import { DataTypes } from 'fitify-ui-landing/src/@types'

const mentionsData: DataTypes.MentionItem[] = [
  {
    image: '/assets/images/mentions/cnn@2x.webp',
    imageSet:
      '/assets/images/mentions/cnn.webp 600w, /assets/images/mentions/cnn@2x.webp 2x, /assets/images/mentions/cnn@2x.webp 1200w',
    width: 85,
    height: 40,
    title: 'Cnn',
    alt: 'Cnn',
  },
  {
    image: '/assets/images/mentions/healthline@2x.webp',
    imageSet:
      '/assets/images/mentions/healthline.webp 600w, /assets/images/mentions/healthline@2x.webp 2x, /assets/images/mentions/healthline@2x.webp 1200w',
    width: 153,
    height: 40,
    title: 'Healthline',
    alt: 'Healthline',
  },
  {
    image: '/assets/images/mentions/elle@2x.webp',
    imageSet:
      '/assets/images/mentions/elle.webp 600w, /assets/images/mentions/elle@2x.webp 2x, /assets/images/mentions/elle@2x.webp 1200w',
    width: 80,
    height: 40,
    title: 'Elle',
    alt: 'Elle',
  },
  {
    image: '/assets/images/mentions/bbc-science-focus@2x.webp',
    imageSet:
      '/assets/images/mentions/bbc-science-focus.webp 600w, /assets/images/mentions/bbc-science-focus@2x.webp 2x, /assets/images/mentions/bbc-science-focus@2x.webp 1200w',
    width: 173,
    height: 40,
    title: 'BBC Science Focus',
    alt: 'BBC Science Focus',
  },
  {
    image: '/assets/images/mentions/bazaar@2x.webp',
    imageSet:
      '/assets/images/mentions/bazaar.webp 600w, /assets/images/mentions/bazaar@2x.webp 2x, /assets/images/mentions/bazaar@2x.webp 1200w',
    width: 130,
    height: 40,
    title: 'Bazaar',
    alt: 'Bazaar',
  },
  {
    image: '/assets/images/mentions/the-times@2x.webp',
    imageSet:
      '/assets/images/mentions/the-times.webp 600w, /assets/images/mentions/the-times@2x.webp 2x, /assets/images/mentions/the-times@2x.webp 1200w',
    width: 192,
    height: 40,
    title: 'The Times',
    alt: 'The Times',
  },
  {
    image: '/assets/images/mentions/vogue@2x.webp',
    imageSet:
      '/assets/images/mentions/vogue.webp 600w, /assets/images/mentions/vogue@2x.webp 2x, /assets/images/mentions/vogue@2x.webp 1200w',
    width: 108,
    height: 40,
    title: 'Vogue',
    alt: 'Vogue',
  },
  {
    image: '/assets/images/mentions/forbes@2x.webp',
    imageSet:
      '/assets/images/mentions/forbes.webp 600w, /assets/images/mentions/forbes@2x.webp 2x, /assets/images/mentions/forbes@2x.webp 1200w',
    width: 126,
    height: 40,
    title: 'Forbes',
    alt: 'Forbes',
  },
]
export { mentionsData }
