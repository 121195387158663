import { biggerPhone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledSection = styled.div`
  &:not(:first-child) {
    padding-top: 120px;
    overflow: hidden;
    ${biggerPhone(css`
      padding-top: 80px;
    `)};
  }
`
