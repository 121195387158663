import { DataTypes } from 'fitify-ui-landing/src/@types'

const reviewsData: DataTypes.ReviewItem[] = [
  {
    name: 'review_8_name',
    description: 'review_8',
  },
  {
    name: 'review_9_name',
    description: 'review_9',
  },
  {
    name: 'review_10_name',
    description: 'review_10',
  },
]

const businessReviewsData: DataTypes.ReviewItem[] = [
  {
    name: 'landing_work_review_1_name',
    job: 'landing_work_review_1_job',
    description: 'landing_work_review_1_body',
  },
  {
    name: 'landing_work_review_2_name',
    job: 'landing_work_review_2_job',
    description: 'landing_work_review_2_body',
  },
  {
    name: 'landing_work_review_3_name',
    job: 'landing_work_review_3_job',
    description: 'landing_work_review_3_body',
  },
]

export { reviewsData, businessReviewsData }
