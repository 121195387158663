import React, { ReactNode } from 'react'

import { StyledSection } from './Section.Styled'

const Section = ({
  id,
  children,
  style,
}: {
  id?: string
  children: ReactNode
  style?: React.CSSProperties
}) => {
  return (
    <StyledSection style={style} id={id}>
      {children}
    </StyledSection>
  )
}

export default Section
