import { DataTypes } from 'fitify-ui-landing/src/@types'
import { ContentRevealAnimation } from 'fitify-ui-landing/src/components/animations/ContentRevealAnimation'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import NativeImage from 'fitify-ui-landing/src/components/Image/NativeImage'
import Section from 'fitify-ui-landing/src/components/Section/Section'

import { StyledMention, StyledMentions } from './Mentions.Styled'

const Mentions = ({
  mentionsItems,
}: {
  mentionsItems: DataTypes.MentionItem[]
}) => {
  return (
    <Section id={'mentions'}>
      <Container>
        <ContentRevealAnimation>
          <StyledMentions>
            {mentionsItems.map((item, index: number) => {
              return (
                <StyledMention key={index}>
                  <NativeImage
                    alt={item.alt}
                    width={item.width.toString()}
                    height={item.height.toString()}
                    src={item.image}
                    srcSet={item.imageSet}
                    loading={'eager'}
                  />
                </StyledMention>
              )
            })}
          </StyledMentions>
        </ContentRevealAnimation>
      </Container>
    </Section>
  )
}

export default Mentions
