import { colors } from 'fitify-ui'
import { phone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledMention = styled.div`
  display: flex;
  margin: 0 auto;
`
export const StyledMentions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  align-items: center;

  padding: 40px 24px 44px;
  background: ${colors.White};
  border: 1px solid ${colors.Gray200};
  border-radius: 24px;

  img {
    width: 100%;
    height: auto;
  }

  ${phone(css`
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    grid-row-gap: 27px;
    border-radius: 16px;

    img {
      width: auto;
      height: 31px;
    }
  `)}
`
