import type { ImageTypes } from 'fitify-ui-landing/src/@types'

const headerImages: ImageTypes.Header[] = [
  {
    headerImage: '/assets/images/header/variant-1@2x.webp',
    headerImageSet:
      '/assets/images/header/variant-1.webp 600w, /assets/images/header/variant-1@2x.webp 2x, /assets/images/header/variant-1@2x.webp 1200w',
  },
  {
    headerImage: '/assets/images/header/variant-2@2x.webp',
    headerImageSet:
      '/assets/images/header/variant-2.webp 600w, /assets/images/header/variant-2@2x.webp 2x, /assets/images/header/variant-2@2x.webp 1200w',
  },
  {
    headerImage: '/assets/images/header/variant-3@2x.webp',
    headerImageSet:
      '/assets/images/header/variant-3.webp 600w, /assets/images/header/variant-3@2x.webp 2x, /assets/images/header/variant-3@2x.webp 1200w',
  },
]

const businessHeaderImage: ImageTypes.Header = {
  headerImage: '/assets/images/header/business-variant.webp',
  headerImageSet: '/assets/images/header/business-variant.webp',
}

export { headerImages, businessHeaderImage }
