import { CSSProperties, ReactNode } from 'react'

import { StyledContainer } from './Container.Styled'

const Container = ({
  children,
  style,
}: {
  children: ReactNode
  style?: CSSProperties
}) => {
  return <StyledContainer style={style}>{children}</StyledContainer>
}

export default Container
