import { colors, StyledIcon, TextStyles } from 'fitify-ui'
import styled, { css } from 'styled-components'

import { phone, tabletPortrait } from '../../../theme/breakpoints'

export const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: ${colors.White};
  padding: 24px;
  margin-right: 24px;
  border: 1px solid ${colors.Gray200};
  border-radius: 24px;

  &:last-child {
    margin-right: 0;
  }

  ${tabletPortrait(css`
    ${StyledIcon} {
      width: 17px;
      height: 17px;
      margin-right: 4px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  `)}

  ${phone(css`
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  `)}
`
export const StyledReviewStars = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 0;

  svg {
    margin-right: 8px;
  }
`
export const StyledReviews = styled.div<{ $isBusiness: boolean }>`
  display: flex;

  ${StyledReview} {
    height: ${(props) => !props.$isBusiness && 'fit-content'};
  }

  ${phone(css`
    flex-direction: column;
  `)}
`
export const StyledReviewsTitle = styled.div<{ $isBusiness: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$isBusiness ? 'start' : 'center')};
  margin-bottom: ${(props) => (props.$isBusiness ? '24px' : '48px')};

  ${TextStyles} {
    max-width: 700px;
    text-align: center;
  }
`
